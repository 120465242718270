//AUTH
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH = "AUTH";
export const ADD_DEVICE_TOKEN = "ADD_DEVICE_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const NO_REGISTER = "NO_REGISTER";
export const REGISTER = "REGISTER";
export const RESET_DATA = "RESET_DATA";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const RESET_MESSAGE_LOGIN = "RESET_MESSAGE_LOGIN";
export const EXPIRED_LICENSE = "EXPIRED_LICENSE";
export const UPDATE_REQUEST_LOGIN = "UPDATE_REQUEST_LOGIN";
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const TRANSFER_BRANCH = "TRANSFER_BRANCH"
//cart
export const ACTION_CART_UPDATE_PRODUCT_LIST =
  "ACTION_CART_UPDATE_PRODUCT_LIST";
export const ACTION_CART_UPDATE_TOTAL_PRICE = "ACTION_CART_UPDATE_TOTAL_PRICE";
export const ACTION_CART_ADD_PRODUCT = "ACTION_CART_ADD_PRODUCT";
export const ACTION_CART_REMOVE_PRODUCT = "ACTION_CART_REMOVE_PRODUCT";
export const ACTION_CART_SELECT_RECEIVE_ADDRESS =
  "ACTION_CART_SELECT_RECEIVE_ADDRESS";
export const ACTION_CART_SUBMIT_SUCCESS = "ACTION_CART_SUBMIT_SUCCESS";
export const ACTION_CART_PRODUCT_UPDATE_QUANTITY =
  "ACTION_CART_PRODUCT_UPDATE_QUANTITY";
export const UPDATE_LIST_FILE_UPLOAD = "UPDATE_LIST_FILE_UPLOAD";

//setting
export const STATE_LANGUAGE = "STATE_LANGUAGE";

// type giỏ hàng
export const ADD_CART = "ADD_CART";
export const UPDATE_CART = "UPDATE_CART"
export const CLEAR_ORDER = "CLEAR_ORDER"
export const UPDATE_PRODUCT_TO_CART = 'UPDATE_PRODUCT_TO_CART'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const ADD_INVOICE_TO_CART = 'ADD_INVOICE_TO_CART'

// type tab invoice
export const CHANGE_TAB_INVOICE = "CHANGE_TAB_INVOICE";
export const ADD_INVOICE = "ADD_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const UPDATE_INVOICE_INFO = "UPDATE_INVOICE_INFO";
export const UPDATE_INVOICE_PRESCRIPTION = "UPDATE_INVOICE_PRESCRIPTION";
// type tab invoice draft
export const CHANGE_TAB_INVOICE_DRAFT = "CHANGE_TAB_INVOICE_DRAFT";
export const ADD_INVOICE_DRAFT = "ADD_INVOICE_DRAFT";
export const DELETE_INVOICE_DRAFT = "DELETE_INVOICE_DRAFT";
export const UPDATE_INVOICE_DRAFT = "UPDATE_INVOICE_DRAFT";
export const CREATE_INVOICE_DRAFT = "CREATE_INVOICE_DRAFT";
export const UPDATE_INVOICE_DRAFT_INFO = "UPDATE_INVOICE_DRAFT_INFO";
export const UPDATE_INVOICE_DRAFT_PRESCRIPTION = "UPDATE_INVOICE_DRAFT_PRESCRIPTION";
export const CHANGE_TAB_INVOICE_DRAFT_CONFIRM = "CHANGE_TAB_INVOICE_DRAFT_CONFIRM";
export const ADD_TAB_INVOICE_DRAFT_CONFIRM = "ADD_TAB_INVOICE_DRAFT_CONFIRM";
export const DELETE_INVOICE_DRAFT_CONFIRM = "DELETE_INVOICE_DRAFT_CONFIRM";
export const UPDATE_INVOICE_DRAFT_CONFIRM = "UPDATE_INVOICE_DRAFT_CONFIRM";
export const UPDATE_INVOICE_DRAFT_CONFIRM_INFO = "UPDATE_INVOICE_DRAFT_CONFIRM_INFO";
export const UPDATE_INVOICE_DRAFT_CONFIRM_PRESCRIPTION = "UPDATE_INVOICE_DRAFT_CONFIRM_PRESCRIPTION";
// type tab invoice wholesale
export const CHANGE_TAB_INVOICE_WHOLESALE = "CHANGE_TAB_INVOICE_WHOLESALE";
export const ADD_INVOICE_WHOLESALE = "ADD_INVOICE_WHOLESALE";
export const DELETE_INVOICE_WHOLESALE = "DELETE_INVOICE_WHOLESALE";
export const UPDATE_INVOICE_WHOLESALE = "UPDATE_INVOICE_WHOLESALE";
export const CREATE_INVOICE_WHOLESALE = "CREATE_INVOICE_WHOLESALE";
export const UPDATE_INVOICE_WHOLESALE_INFO = "UPDATE_INVOICE_WHOLESALE_INFO";

export const CHANGE_TAB_INVOICE_COMBO = "CHANGE_TAB_INVOICE_COMBO";
export const ADD_INVOICE_COMBO = "ADD_INVOICE_COMBO";
export const DELETE_INVOICE_COMBO = "DELETE_INVOICE_COMBO";
export const UPDATE_INVOICE_COMBO = "UPDATE_INVOICE_COMBO";
export const CREATE_INVOICE_COMBO = "CREATE_INVOICE_COMBO";
export const UPDATE_INVOICE_COMBO_INFO = "UPDATE_INVOICE_COMBO_INFO";

// type tab import
export const CHANGE_TAB_IMPORT = "CHANGE_TAB_IMPORT";
export const ADD_TAB_IMPORT = "ADD_TAB_IMPORT";
export const DELETE_TAB_IMPORT = "DELETE_TAB_IMPORT";
export const SAVE_INFO_IMPORT = "SAVE_INFO_IMPORT";
// type tab export inventory
export const ADD_TAB_EXPORT = "ADD_TAB_EXPORT";
export const DELETE_TAB_EXPORT = "DELETE_TAB_EXPORT";
export const CHANGE_TAB_EXPORT = "CHANGE_TAB_EXPORT";
export const SAVE_INFO_EXPORT = "SAVE_INFO_EXPORT";
// type check inventory
export const ADD_DRUG_CHECK_INVENTORY = 'ADD_DRUG_CHECK_INVENTORY'
export const DELETE_DRUG_CHECK_INVENTORY = 'DELETE_DRUG_CHECK_INVENTORY'
export const REMOVE_DRUG_CHECK_INVENTORY = 'REMOVE_DRUG_CHECK_INVENTORY'
export const IMPORT_EXCEL_DRUG_CHECK_INVENTORY = 'IMPORT_EXCEL_DRUG_CHECK_INVENTORY'
export const IMPORT_DELETE_ALL_NEW_PRODUCT = "IMPORT_DELETE_ALL_NEW_PRODUCT"
//  type drug
export const ADD_DRUG_CREATE = "ADD_DRUG_CREATE";
export const DELETED_DRUG_CREATE = "DELETED_DRUG_CREATE";
export const UPDATE_DRUG_CREATE = "UPDATE_DRUG_CREATE";
export const REMOVE_DRUG_CREATE = "REMOVE_DRUG_CREATE";
//  type dataPersist
export const UNIT_TYPE = "UNIT_TYPE";
export const KIND_DRUG = "KIND_DRUG";
export const GROUP_DRUG = "GROUP_DRUG";
export const DESCRIPTION = "DESCRIPTION";
export const TYPE_IMPORT = "TYPE_IMPORT";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SAVE_STORE_LICENSE = "SAVE_STORE_LICENSE";
export const SAVE_STORE_PACKAGE = "SAVE_STORE_PACKAGE";
export const SAVE_COMPANY_TYPE = 'SAVE_COMPANY_TYPE';
export const GET_LIST_PACKAGE = "GET_LIST_PACKAGE";
export const GET_INFO_USER = "GET_INFO_USER";
export const ADD_LIST_DRUG_PHARMACY = "ADD_LIST_DRUG_PHARMACY";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const TYPE_EXPORT = "TYPE_EXPORT";
export const GROUP_CUSTOMER = "GROUP_CUSTOMER";
export const REMOVE_DRUG_PHARMACY = 'REMOVE_DRUG_PHARMACY';
export const ARRAY_DRUG_ERROR = "ARRAY_DRUG_ERROR";
export const REMOVE_DRUG_ERROR = "REMOVE_DRUG_ERROR";
export const GET_ALL_STORE_PHARMACY = 'GET_ALL_STORE_PHARMACY';
export const SAVE_LOCATION = "SAVE_LOCATION";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const GET_ALL_INITIAL_DATA = "GET_ALL_INITIAL_DATA";
export const REQUEST_UPDATE_PRODUCT_SORT = "REQUEST_UPDATE_PRODUCT_SORT";
export const REQUEST_UPDATE_GROUP_CUSTOMER = "REQUEST_UPDATE_GROUP_CUSTOMER";
export const REQUEST_UPDATED_LIST_USER = "REQUEST_UPDATED_LIST_USER";
export const GET_LIST_USER = "GET_LIST_USER";
export const ADD_LIST_CASHFLOW_TYPE = 'ADD_LIST_CASHFLOW_TYPE'
export const ADD_LIST_USER_GROUP = 'ADD_LIST_USER_GROUP'
export const ADD_LIST_CASHFLOW_PARTNER_TYPE = 'ADD_LIST_CASHFLOW_PARTNER_TYPE'
export const ADD_CURRENT_VERSION = 'ADD_CURRENT_VERSION'
export const SAVE_INVOICE_SOURCE = 'SAVE_INVOICE_SOURCE'
export const UPDATE_LIST_PRODUCT_PHARMACY = 'UPDATE_LIST_PRODUCT_PHARMACY'
export const SAVE_INFO_COMPANY = 'SAVE_INFO_COMPANY'
export const SAVE_INFO_BRAND_NAME = 'SAVE_INFO_BRAND_NAME'
export const SAVE_INFO_ZALO_OA = 'SAVE_INFO_ZALO_OA'
export const GET_LIST_CAMPAGIN = 'GET_LIST_CAMPAGIN'
// type update drug

export const ADD_DRUG_UPDATE_EXCEL = "ADD_DRUG_UPDATE_EXCEL";
export const ARRAY_DRUG_UPDATE_ERROR = "ARRAY_DRUG_UPDATE_ERROR";
export const DELETED_DRUG_UPDATE_CREATE = "DELETED_DRUG_UPDATE_CREATE";
export const UPDATE_DRUG_EXCEL_CREATE = 'UPDATE_DRUG_EXCEL_CREATE';
export const REMOVE_DRUG_UPDATE_CREATE = "REMOVE_DRUG_UPDATE_CREATE";
export const REMOVE_DRUG_UPDATE_ERROR = "REMOVE_DRUG_UPDATE_ERROR";
export const UPDATE_DRUG_PRINT = "UPDATE_DRUG_PRINT";
export const CHANGE_STORE_UPDATE_PRICE = "CHANGE_STORE_UPDATE_PRICE";

// action setting

export const CHANGE_TYPE_PRINT = "CHANGE_TYPE_PRINT";
export const CHANGE_SHOW_USE_GUIDE = "CHANGE_SHOW_USE_GUIDE";
export const CHANGE_TYPE_SHOW_PRODUCT = "CHANGE_TYPE_SHOW_PRODUCT";
export const CHANGE_POLICY_RETURN_INVOICE = "CHANGE_POLICY_RETURN_INVOICE";
export const CHANGE_POLICY_RETURN_INVOICE_WHOLESALE = 'CHANGE_POLICY_RETURN_INVOICE_WHOLESALE';
export const CHANGE_TYPE_PRINT_INVOICE = "CHANGE_TYPE_PRINT_INVOICE";
export const CHANGE_TYPE_OPTION_PRINT_BARCODE = "CHANGE_TYPE_OPTION_PRINT_BARCODE";
export const CHANGE_OPTIONS_PRINT_BARCODE = "CHANGE_OPTIONS_PRINT_BARCODE";
export const CHANGE_TYPE_BUSINESS = "CHANGE_TYPE_BUSINESS";
export const CHANGE_TYPE_SYNC = "CHANGE_TYPE_SYNC"
export const UPDATE_SETTING = "UPDATE_SETTING";
export const CHANGE_TYPE_BUSINESS_FORM = "CHANGE_TYPE_BUSINESS_FORM";
export const INIT_SETTING_GENERAL = 'INIT_SETTING_GENERAL';
export const CHANGE_QR_CODE = "CHANGE_QR_CODE"

// type combo
export const UPDATE_COMBO = 'UPDATE_COMBO'
export const RESET_COMBO = 'RESET_COMBO'

// type sms campaign
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_DETAIL = 'CREATE_CAMPAIGN_DETAIL'
export const UPDATE_CAMPAIGN_DETAIL = 'UPDATE_CAMPAIGN_DETAIL'
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN'
// Modal upgrade
export const SHOW_MODAL_UPGRADE = 'SHOW_MODAL_UPGRADE'
// Column show
export const UPDATE_TABLE_INVOICE = 'UPDATE_TABLE_INVOICE'
export const UPDATE_TABLE_PRODUCT_PHARMACY = 'UPDATE_TABLE_PRODUCT_PHARMACY'
export const UPDATE_TABLE_HISTORY_IMPORT = 'UPDATE_TABLE_HISTORY_IMPORT'
export const UPDATE_TABLE_CREATE_INVOICE_NEW = 'UPDATE_TABLE_CREATE_INVOICE_NEW'

export const RESET_TABLE_INVOICE = 'RESET_TABLE_INVOICE'
export const RESET_TABLE_PRODUCT_PHARMACY = 'RESET_TABLE_PRODUCT_PHARMACY'
export const RESET_TABLE_CREATE_INVOICE_NEW = 'RESET_TABLE_CREATE_INVOICE_NEW'
// Data Sample
export const CREATE_SAMPLE_DATA = 'CREATE_SAMPLE_DATA'
export const UPDATE_FISRT_LOGIN = 'UPDATE_FISRT_LOGIN'
export const UPDATE_IS_LOADING_DASHBOARD = 'UPDATE_IS_LOADING_DASHBOARD'
// Option Print Invoice
export const UPDATE_OPTION_PRINT_INVOICE = 'UPDATE_OPTION_PRINT_INVOICE'
//
export const UPDATE_AMOUNT_PAID_INVOICE_COMBO = 'UPDATE_AMOUNT_PAID_INVOICE_COMBO'
export const UPDATE_AMOUNT_PAID_INVOICE = 'UPDATE_AMOUNT_PAID_INVOICE'
export const UPDATE_AMOUNT_PAID_INVOICE_DRAFT_COMFIRM = 'UPDATE_AMOUNT_PAID_INVOICE_DRAFT_COMFIRM'
export const UPDATE_AMOUNT_PAID_INVOICE_DRAFT = 'UPDATE_AMOUNT_PAID_INVOICE_DRAFT'
export const UPDATE_AMOUNT_PAID_INVOICE_WHOLESALE = 'UPDATE_AMOUNT_PAID_INVOICE_WHOLESALE'

//setting print
export const INIT_SETTING_PRINT = 'INIT_SETTING_PRINT'
//promotion
export const UPDATE_TYPE_PRMOTION = 'UPDATE_TYPE_PRMOTION'
export const UPDATE_DETAIL_PROMOTION = 'UPDATE_DETAIL_PROMOTION'
export const RESET_PROMOTION = 'RESET_PROMOTION'
export const UPDATE_PROMOTION_INVOICE = 'UPDATE_PROMOTION_INVOICE'
export const ADD_PROMOTION_GIFT = 'ADD_PROMOTION_GIFT'
export const UPDATE_PROMOTION_PRODUCT = 'UPDATE_PROMOTION_PRODUCT'
export const ADD_PRODUCT_GIFT_TO_INVOICE = 'ADD_PRODUCT_GIFT_TO_INVOICE'
// Loyalty
export const SAVE_LOYALTY_RANK = 'SAVE_LOYALTY_RANK'
export const SAVE_COMPANY_LOYALTY = 'SAVE_COMPANY_LOYALTY'
export const UPDATE_LOYALTY_EXCHANGE_ECOIN = 'UPDATE_LOYALTY_EXCHANGE_ECOIN'
export const UPDATE_LOYALTY_EARN_ECOIN = 'UPDATE_LOYALTY_EARN_ECOIN'
// popup banber
export const UPDATE_SHOW_BANNER = 'UPDATE_SHOW_BANNER'
export const RESET_INVOICE_PRESCRIPTION = 'RESET_INVOICE_PRESCRIPTION'

export const PROMOTION_NEW = {
  UPDATE_PROMOTION_NEW: 'UPDATE_PROMOTION_NEW',
  CHANGE_TYPE_APPLICABLE_TYPE : 'CHANGE_TYPE_APPLICABLE_TYPE',
  RESET_PROMOTION_NEW: 'RESET_PROMOTION_NEW',
}

export const UPDATE_LOYALTY_EXCHANGE_ECOIN_COMBO = 'UPDATE_LOYALTY_EXCHANGE_ECOIN_COMBO'
export const UPDATE_LOYALTY_EARN_ECOIN_COMBO = 'UPDATE_LOYALTY_EARN_ECOIN_COMBO'
